import React, { useState } from "react";
import {
  AppBar,
  Collapse,
  Divider,
  Drawer,
  List,
  IconButton,
  Button,
  ListItem,
  ListItemText,
  Toolbar,
} from "@material-ui/core/";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "gatsby";
import {
  HomeIcon,
  CodeBracketIcon,
  QueueListIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAdminState } from "../context/AdminContext";
import { logout } from "../services/auth";
import Logo from "../images/CPAxtra-Lotuss_Logo.png";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, marginBottom: 70 },
  button: { float: "right", color: "#fff" },
  menuButton: { fill: "#fff" },
  title: { flexGrow: 1, paddingTop: 0, marginBottom: 0 },
  link: { color: "#000", textDecoration: "none" },
  logo: { position: "relative", top: 15, width: 200 },
  list: {
    width: 280,
    background: "#f4f3ef",
    height: "100vh",
    position: "relative",
  },
  svg: { marginRight: theme.spacing(1), width: 18 },
  listItem: { paddingTop: 4, paddingBottom: 0 },
  userInfo: { paddingBottom: 0, marginBottom: "7px" },
  dashboard: { marginTop: "-20px", marginBottom: "-10px", paddingLeft: 20 },
  menuContainer: { maxHeight: "83%", overflowY: "auto" },
  icons: { width: 25, marginRight: 10, position: "relative", top: 10 },
  topMenu: { cursor: "pointer" },
}));

const Header = () => {
  const classes = useStyles();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDashboardOpen, setDashboardOpen] = useState(true);
  const isBrowser = () => typeof window !== "undefined";
  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const onClickLogout = () => {
    if (isBrowser()) {
      logout();
      window.location = "/login";
    }
  };

  const toggleDashboard = () => setDashboardOpen(!isDashboardOpen);
  const { profile } = useAdminState().login.user || {};

  return (
    <div className={classes.root}>
      <AppBar position="static" className="appbar">
        <Toolbar>
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon className={classes.menuButton} />
          </IconButton>
          <h1 className={classes.title} onClick={() => navigate("/")}>
            <img className={classes.logo} alt="Logo" src={Logo} />
          </h1>
          <Button className={classes.button} onClick={onClickLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className={classes.list} role="presentation">
          <List>
            <ListItem className={classes.userInfo}>
              <ListItemText primary={`Hi! ${profile?.given_name || ""}`} />
            </ListItem>
          </List>
          <Divider />
          <List className={classes.listItem} onClick={toggleDrawer(false)}>
            <ListItem button onClick={() => navigate("/")}>
              <HomeIcon className={classes.svg} />
              <ListItemText primary="Home" />
            </ListItem>
          </List>

          <div className={classes.menuContainer}>
            <List className={classes.listItem}>
              <ListItem
                button
                onClick={toggleDashboard}
                className={classes.topMenu}
              >
                <SquaresPlusIcon className={classes.svg} />
                <ListItemText primary="Dashboard" />
                {isDashboardOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </List>
            <Collapse
              in={isDashboardOpen}
              timeout="auto"
              unmountOnExit
              className={classes.dashboard}
            >
              <List onClick={toggleDrawer(false)}>
                <ListItem
                  button
                  onClick={() => {
                    toggleDrawer(false);
                    navigate("/dashboard/localDev");
                  }}
                  className={classes.listItem}
                >
                  <CodeBracketIcon className={classes.svg} />
                  <ListItemText primary="Local Dev" />
                </ListItem>
              </List>
            </Collapse>

            <List className={classes.listItem} onClick={toggleDrawer(false)}>
              <ListItem
                button
                onClick={() => navigate("/projectList")}
                className={classes.listItem}
              >
                <QueueListIcon className={classes.svg} />
                <ListItemText primary="Project List - Local Dev" />
              </ListItem>
            </List>

            <List className={classes.listItem} onClick={toggleDrawer(false)}>
              <ListItem
                button
                onClick={() => navigate("/resources")}
                className={classes.listItem}
              >
                <QueueListIcon className={classes.svg} />
                <ListItemText primary="Resources" />
              </ListItem>
            </List>

            {/* <List className={classes.listItem} onClick={toggleDrawer(false)}>
              <ListItem
                button
                onClick={() => navigate("/budget")}
                className={classes.listItem}
              >
                <BanknotesIcon className={classes.svg} />
                <ListItemText primary="Budget Dashboard" />
              </ListItem>
            </List> */}
            {/* <List className={classes.listItem} onClick={toggleDrawer(false)}>
              <Link
                className={classes.link}
                target="_blank"
                to="https://thlotuss.sharepoint.com/:x:/r/teams/523/_layouts/15/Doc.aspx?sourcedoc=%7B43C59474-6D76-44F7-86C3-1C05D246E446%7D&file=BookingSheet.xlsx&action=default&mobileredirect=true"
              >
                <ListItem button className={classes.listItem}>
                  <BookOpenIcon className={classes.svg} />
                  <ListItemText primary="Booking Sheet" />
                </ListItem>
              </Link>
            </List>
            <List className={classes.listItem} onClick={toggleDrawer(false)}>
              <Link
                className={classes.link}
                target="_blank"
                to="https://thlotuss.sharepoint.com/:x:/r/teams/523/_layouts/15/doc2.aspx?sourcedoc=%7B6d5ab689-a315-4904-9aa9-a6b697969c3c%7D&action=editnew&CID=065a8455-d28b-0d9f-90bf-53061874d2c5"
              >
                <ListItem button className={classes.listItem}>
                  <BookOpenIcon className={classes.svg} />
                  <ListItemText primary="MY - Projects" />
                </ListItem>
              </Link>
            </List> */}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
