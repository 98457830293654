import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import "./layout.css";
import { Wrapper } from "./styles";
import Snackbar from "../shared/Snackbar";
import { navigate } from "gatsby";
import { adminContextStore } from "../context/store/createStore";
import { SET_LOGIN } from "../context/actions/auth";
import { getUser } from "../services/auth";

const Layout = ({ children }) => {
  const { dispatch } = adminContextStore;
  const isBrowser = typeof window !== "undefined";
  const fetchUser = useCallback(async () => {
    const currentUser = await getUser();
    if (currentUser) {
      dispatch({
        type: SET_LOGIN,
        payload: {
          submitting: false,
          isLogin: true,
          user: JSON.parse(isBrowser && localStorage.getItem("user")),
        },
      });
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [dispatch]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Wrapper>
      <Snackbar />
      <Header />
      <main>{children}</main>
      <footer>© {new Date().getFullYear()}, Lotuss PMO</footer>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
